exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-additional-information-js": () => import("./../../../src/pages/additional-information.js" /* webpackChunkName: "component---src-pages-additional-information-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-data-upload-js": () => import("./../../../src/pages/data-upload.js" /* webpackChunkName: "component---src-pages-data-upload-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-licensing-quotes-js": () => import("./../../../src/pages/licensing/quotes.js" /* webpackChunkName: "component---src-pages-licensing-quotes-js" */),
  "component---src-pages-licensing-thank-you-jsx": () => import("./../../../src/pages/licensing/thank-you.jsx" /* webpackChunkName: "component---src-pages-licensing-thank-you-jsx" */),
  "component---src-pages-meeting-js": () => import("./../../../src/pages/meeting.js" /* webpackChunkName: "component---src-pages-meeting-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-api-js": () => import("./../../../src/pages/products/api.js" /* webpackChunkName: "component---src-pages-products-api-js" */),
  "component---src-pages-publisher-wizard-js": () => import("./../../../src/pages/publisher-wizard.js" /* webpackChunkName: "component---src-pages-publisher-wizard-js" */),
  "component---src-pages-reporting-quotes-jsx": () => import("./../../../src/pages/reporting/quotes.jsx" /* webpackChunkName: "component---src-pages-reporting-quotes-jsx" */),
  "component---src-pages-reporting-thank-you-jsx": () => import("./../../../src/pages/reporting/thank-you.jsx" /* webpackChunkName: "component---src-pages-reporting-thank-you-jsx" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../../../src/templates/blog-post/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */),
  "component---src-templates-services-services-js": () => import("./../../../src/templates/services/services.js" /* webpackChunkName: "component---src-templates-services-services-js" */),
  "component---src-templates-song-info-song-info-js": () => import("./../../../src/templates/song-info/song-info.js" /* webpackChunkName: "component---src-templates-song-info-song-info-js" */)
}

