const React = require('react');
const HubspotChat = require('./src/components/hubspot-chat').default;
const CookieBanner = require('./src/components/cookie-banner').default;
const wrapWithProvider = require('./wrap-with-provider').default;
const api = require('./src/utils/api').default;
const Cookies = require('universal-cookie');
const cookies = new Cookies();
const LogRocket = require('logrocket');

require('rsuite/dist/rsuite.min.css');
require('./src/scss/base.scss');

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <>
      {element}
      <CookieBanner />
      <HubspotChat />
    </>
  );
};

exports.onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.init(process.env.GATSBY_LOGROCKET_ID);
    console.log('Authenticating...');
    api.authenticate({
      username: process.env.GATSBY_WEBSITE_USER,
      password: process.env.GATSBY_WEBSITE_AUTH,
    });
  }
};

exports.onRouteUpdate = ({ location }) => {
  if (location.pathname.includes('sign-up')) {
    console.log('Refreshing token...');
    api.refreshAuth();
  }
  if (location.hash) {
    setTimeout(() => {
      document
        .querySelector(`${location.hash}`)
        .scrollIntoView({ behavior: 'smooth' });
    }, 200);
  }

  const abCookie = cookies.get('ab');
  const variant = abCookie && abCookie['dnd-ab-test'];

  if (window && window.heap && window.heap.addEventProperties && variant) {
    window.heap.clearEventProperties();
    window.heap.addEventProperties({ dnd_ab_test: variant });
  }
};

exports.shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  // Don't scroll to top when clicking on a link with a license query param, used in the apps and services page
  if (location.pathname.includes('/services/apps-services/')) {
    // if navigating from the same page, don't update scrolling (use for tabs)
    if (
      prevRouterProps &&
      prevRouterProps.location.pathname === location.pathname
    ) {
      return false;
    }
  }

  // default behaviour for the rest of the pages
  return true;
};

exports.wrapRootElement = wrapWithProvider;
